@import '~antd/dist/antd.css';
.App {
    display: flex;
}

.MapContainer{
  flex: 2;
  height: 100vh;
}

.destinationList{
  flex: 1;
}

#displaytable{
  border-collapse: collapse;
}
#displaytable tr td{
  border: 1px solid black;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td{
  padding: 2px 3px;
}
.ant-form-item{
  margin: 5px 0px;
}